import { Box, Typography } from "@mui/material";
import React from "react";

function AppDownloadContainer({
  isMobile,
  isTab,
}: {
  isMobile: boolean;
  isTab: boolean;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          height: isMobile ? "80%" : "100%",
          width: isMobile ? (isTab ? "45" : "100%") : "45%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            // position: "absolute",
            display: "flex",
            gap: 1,
            flexDirection: "column",
            // left: "5%",
            // bottom: "30%",
            maxWidth: {
              xs: "60%",
              sm: "45%",
              md: "50%",
              lg: "60%",
            },
          }}
        >
          <DownloadBanner />
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <img
              src="/images/zipangLandingPage/playStore.png"
              style={{
                width: "47%",
                maxHeight: 60,
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.myauctions.zipang",
                  "_blank"
                );
              }}
              alt="PLAY STORE"
            />
            <img
              src="/images/zipangLandingPage/appStore.png"
              style={{
                width: "47%",
                maxHeight: 60,
              }}
              onClick={() => {
                window.open(
                  "https://apps.apple.com/us/app/zipang-auctions/id6475668664",
                  "_blank"
                );
              }}
              alt="APP STORE"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AppDownloadContainer;

const DownloadBanner = () => (
  <Box sx={{ bgcolor: "#cc153b", padding: 1, borderRadius: 1, color: "white" }}>
    <Typography
      sx={{
        whiteSpace:"nowrap",
        fontSize: {
          xs: "10px", // Extra small screens
          sm: "10px", // Small screens
          md: "10px", // Medium screens
          lg: "20px", // Large screens
        },

      }}
      textAlign={"center"}
      fontWeight={"bold"}
    >
      DOWNLOAD AUCTION APP NOW!
    </Typography>
  </Box>
);
