export default {
  common: {
    hi: "Hi",
    menuText: "Menu",
    createNow: "Create Now",
    bid: "Bid",
    message: "Message",
    liveAuc: "Live Auction",
    prevAuc: "Previous Auction",
    liveFeatureAuc: 'Featured Products',
    startsin: "Starts in",
    endsin: 'Ends in',
    buyNow: "Buy Now",
    buyItNowPrice: "Buy It Now Price",
    auc: "Auction",
    live: "Live",
    previous: "Previous",
    filSearch: "Filter Your Search",
    newUserSignUp: "New User? Sign Up here",
    h2l: "High to Low",
    l2h: "Low to High",
    filSrch: "Filter Search",
    jwlry: "Jewellery",
    diamond: "Diamond",
    items: "Items",
    refine: "Refine",
    reset: "Reset",
    zipangText:
      "Zipang Auctions is an exclusive B2B auction for our verified industry business houses across the World",
    signIntoYourAcc: "Log Into your Account",
    hiw: "How it works",
    whyus: "Why Choose Us?",
    videoUrl: "https://youtu.be/aGfOPky_VrU",
    savedSuccessfully: "Saved Successfully",
    updatedSuccessfully: "Updated Successfully",
    deletedSuccessfully: "Deleted Successfully",
    videoUrlNew: "https://www.youtube.com/watch?v=5bXpZ2Wrs8k",
    logout: {
      logout: "Logout",
    },
    notificaiton: {
      notification: "Notification",
      new: "New",
      showAll: "SHOW ALL NOTIFICATIONS",
      all: "ALL",
      removeFromThe: "You are removed from the Auction.",
      markAsRead: "mark as read",
    },
    testimonials: {
      1: "My Auctions is by far the best auction system I have seen. I previously used other auction  houses' websites, but yours takes all hands down. It works 100% smoothly.",
      2: "My Auctions is a user-friendly website for selling and bidding. Everyone should take notes from this website and learn how to run auctions online.",
    },
    exploreAuctionsCards: {
      1: "Zipang Auctions is a B2B marketplace for diamond traders in Japan. It offers 360-degree images, popcorn bidding, and monthly live bids",
      2: "#365 Days Auction lets buyers and sellers auction jewelry at any time and anywhere",
      3: "Other Auctions program support small firms to auction on the international market.  If you are a small business contact us right away!",
    },
    whyUs: {
      1: {
        title: "Global Outreach",
        1: "We extensively market the Japanese Pawn market worldwide",
        2: "We provide a network of 5473 industry companies",
        3: "Your products reach an opening rate of 90%",
        4: "We undertake to establish your local business globally",
      },
      2: {
        title: "Budget Friendly",
        1: "Get the highest price for your pre-loved jewelry",
        2: "Low commission fee of 3% to sellers and 0.5% for our campaign that supports small businesses",
        3: "Low-priced rents for online auctions and personnel",
        4: "Free and automated email reminders, faxes, and customized invoices",
      },
      3: {
        title: "Data Security",
        1: "We protect and encrypt all your sensitive information.",
        2: "we maintain the accuracy of any data confirmed by you",
        3: "We run hundreds of KYC checks to verify all your bidders .",
        4: "Our trusted logistic team guarantees zero scams and frauds",
      },
    },
    sellPages: {
      success: "Hold Tight! Our experts have started working on it.",
      title: "Sell your diamonds",
      heading: "Get your quick",
      heading1: " and accurate pricing!",
      subheading:
        "My Auctions is a trustworthy business partner. We will work with you to reach more than 5000 foreign clients that understand the worth of jewelry. We promise that you will generate significant earnings.",
      areYouBuss: "Are you a business?",
      visitSell: "Visit the Sellers portal",
      or: "or",
      desOfStone: "Description of Stone",
      prodDesc: "Product Description if any",
      setInJewel: "Set in Jewelry",
      uploadPhoto: "Upload Photo",
      uploadCert: "Upload Certificate",
      bulkUpload: "Bulk Upload",
      companyName: "Company Name",
      enterValue: "JPY (Enter value)",
      estimation: "Have Estimation From Other",
      select: "Please Select",
      click: "Click here",
      note: "Note:",
      options: {
        1: "1. I will visit your office for estimation.",
        2: "2. Send me the estimation post kit.",
        3: "3. I would like to put my diamond CGL Lab for grading (request coupon).",
        note: "We will bear all the shipping charges.",
        note2:
          "In Case we complete a transaction, an identification copy shall be required.",
      },
    },
    hero: {
      0: {
        heading1: "Your Partners in",
        heading2: "Selling and Buying",
        subHeading: "We are Japan's fastest growing online auction",
      },
      1: {
        heading1: "Japan’s 1st Auction ",
        heading2: "Marketplace for Pre-loved Jewelry",
        subHeading: "Make High Revenues at the Lowest Commission Rate.",
      },
      2: {
        heading1: "Access the Japanese",
        heading2: "Pawn Market At Your Fingertips",
        subHeading: "Discover Top Quality Pre-loved Jewelry Sold Only in Japan",
      },
      3: {
        heading1: "Get Your Personalized",
        heading2: "Auction Website in a Flash",
        subHeading: "Our Qualified Experts Support Your Business 24/7",
      },
      4: {
        heading1: `"365 Days" featuring "Buy It Now" is Here!"`,
        heading2: "A New Way to Auction",
        subHeading: "Buy Desired Products Instantly",
      }
    },
    header: {
      logoText: "MY AUCTIONS",
      buttons: {
        addAsFriend: "ADD AS A FRIEND",
        login: "Login",
        loginNew: "Login",
        register: "Register",
        aboutUs: "ABOUT US",
        sellYDiamonds: "Sell Your Diamonds",
        buyDiamonds: "Buy Diamonds",
        buy: "Buy",
        sell: "Sell",
        ea: "Explore Auctions",
      },
      menu: {
        jpa: "ZiPang Auctions",
        oa: "Other Auctions",
        "365a": "#365 Days Auctions",
        ma: "Manage your auctions",
        sd: "Sell Diamonds",
      },
    },
    subHeader: {
      logoText: "MY AUCTIONS",
      buttons: {
        whyUs: "Why Us",
        howItWorks: "How It Works",
        media: "Media",
        aboutUs: "About Us",
        contactUs: "Contact Us",
        testimonials: "Testimonials",
        team: "The Team",
        incubator: "Incubator",
      },
    },
    loader: {
      loading: "Loading...",
      comingSoon: "COMING SOON",
    },
    languages: {
      en: "EN",
      jp: "JP",
      ch: "CH",
    },
    headerMenu: {
      expOptions: "Explore auctions",
    },
    button: {
      signIn: "SIGN IN",
      signInAsAuction: "Sign in As Auctioneer",
      createAccount: "CREATE ACCOUNT",
      cancel: "CANCEL",
      cancel_small: "Cancel",
      save: "SAVE",
      changePassword: "CHANGE PASSWORD",
      changePlan: "CHANGE PLAN",
      ok: "OK",
      filter: "FILTER",
      search: "SEARCH",
      searchRemark: "Remarks Search",
      reset: "RESET",
      note: "Note",
      preBid: "Pre-Bid",
      cancelPreBid: "Cancel Pre-Bid",
      participate: "PARTICIPATE",
      join: "JOIN",
      advanceSearch: "ADVANCE",
      basicSearch: "BASIC",
      otpSignIn: "Sign in using OTP",
      passwordSignIn: "Sign in using Password",
      resendOtp: "Resend OTP",
      resendOtpText: "Resend in",
      resendOtpMinutes: "minutes",
      resendOtpSeconds: "sec",
      seconds: "sec",
      verify: "VERIFY",
      savePassword: "SET PASSWORD",
      savePasswordText:
        "Set your user account password to continue to My Auctions",
    },
    menu: {
      home: "HOME",
      preview: "PREVIEW",
      auction: "AUCTION",
      watchlist: "Watchlist",
      invoice: "INVOICE",
      profile: "PROFILE",
      inquiry: "SUPPORT",
      pendingProduct: "PENDING PRODUCT",
      productAuction: "365 DAYS AUCTION",
      mypage: "MY-PAGE",
    },
    footer: {
      copyrightFooter: "All Rights Reserved. ",
      company: "MY AUCTIONS",
      copyright: "2020-21 All Rights Reserved. ",
      officeNumber: "O: +81358161188",
      faxNumber: "Fax: +81358161189",
      officeAddressOld:
        "201 First Building, 3-10-1 Ueno Taitou Ku Tokyo 1100005",
      officeAddress: "4 Chome-10-7 Taito, Taito City, Tokyo 110-0016",
      emailId: "zipangauctions@gmail.com",
      hokuyu: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 09043537782",
        faxNumber: "Fax: -",
        officeAddress: "2-7-6 , Shinjuku, Shinjuku Ward, Tokyo Japan",
        emailId: "koakuma2001@docomo.ne.jp",
      },
      zipangcompany: "Zipang Auctions HK Ltd. ",
      zipangofficeAddress: "Room 27, Workshop No. 20-24 2nd Floor",
      zipangofficeAddresssecond: "Block B Focal Industrial Centre, 21 Man Lok Street",
      zipangofficeAddressthird:'Hung Hom, Kowloon, Hong Kong',
      zipangofficeNumber:'O: +852 64203650',
      zipangofficeDPMsNumber:'DPMS No. : B-B-24-03-06147'
    },
    subdomainFooter: {
      emailId: "mjdiamondsco@gmail.com",
      company: "Powered by MY AUCTIONS",
      hokuyu: {
        company: "株式会社　北友 ",
        officeNumber: "09043537782",
        officeAddress: "2-7-6 , Shinjuku, Shinjuku Ward, Tokyo Japan",
        emailId: "koakuma2001@docomo.ne.jp",
      },
    },
    noMoreResultsToShow: "No more results to show",
    socialMedia: {
      facebook: "Facebook",
      pinterest: "Pinterest",
      twitter: "Twitter",
    },
    colorFilter: {
      all: "All",
      pink: "Pink(Sold)",
      orange: "Orange(Hold)",
      white: "White(Unsold)",
    },
    landingPage: {
      heading: "Worlds First Auction Rental Website",
      newHeading: "All Auction Tools at One Portal",
      category: {
        home: "HOME",
        diamonds: "DIAMONDS",
        bags: "BAGS",
        accessories: "ACCESSORIES",
        watch: "WATCH",
        shoes: "SHOES",
        jewellery: "JEWELLERY",
        septTitle: "January Auction"
      },
      // text: 'Having an online auction has never been easier. You can rent "My Auction" to organise auction for your products. We bring together the power of world class ecommerce technologies and make them easy and accessible for your business success.',
      text: "Having an online auction has never been easier. We bring together the power of world class ecommerce technologies and make them easy and accessible for your business success.",
      whyus: "WHY US",
      becomeaMember: "BECOME A MEMBER",
      freeTrial: "14 Days Free Trial",
      pointers: {
        firstOption: "PRIVACY ENSURED",
        secondOption: "MINIMAL RISK",
        thirdOption: "CHEAPEST",
        fourthOption: "GLOBAL OUTREACH",
      },
      keyPointers: {
        firstPointer: {
          firstOption:
            "We intend to protect your personal information and to maintain its accuracy as confirmed by you.",
          secondOption:
            "We encrypt all sensitive personal information when we transmit such information over the internet.",
        },
        secondPointer: {
          firstOption:
            "All the bidders are onboarded after regressive verification and kyc process.",
        },
        thirdPointer: {
          firstOption: "Personnel staff cost reduces to one fourth",
          secondOption: "Automatic reminder mails and fax for free",
          thirdOption: "Renting auction space reduced to 1/5th",
          fourthOption: "Automatic invoice generation for free",
        },
        fourthPointer: {
          firstOption:
            "We expose your products to thousands of our international clients.",
          secondOption: "No need for foreign language skills. ",
        },
      },
      howItWorks: "HOW IT WORKS",
      list: {
        noOfProducts: "No. of Products",
        preview: "Preview Date(UTC)",
        auction: "Auction Date(UTC)",
        participate: "PARTICIPATE",
      },
      floatIcon: "BECOME A MEMBER",
      banner: {
        first: {
          firstText: "TAKE YOUR AUCTION ONLINE WITH US",
          secondText: "ONLY @99$ ANNUALY",
        },
        second: {
          firstText: "BID WITH US THROUGH INVITATION",
          secondText: "PAYING $0 ANNUALLY",
          thirdText: "No invitation code? Become a Bidder only @99$ annually",
        },
        third: {
          firstText: "BECOME AUCTION ORGANIZER & BIDDER BOTH",
          secondText: "ONLY @149$ ANNUALY",
        },
        fourth: {
          firstText: "LOOKING FOR A SPACE TO AUCTION YOUR PRODUCTS",
          secondText: "RENT IT AT 'MY AUCTIONS'",
          thirdText: "SWIPE RIGHT TO CHECK PRICING",
        },
      },
      whyMyAuction: {
        heading: "Why Us",
        firstLine: "In Old times",
        secondLine: "NOW",
      },
      testMoniels: {
        heading: "Media",
        desFrist: "昨今のオークションで最も公平性なオークションです",
        desSecond: "日本の中で最も使い易くわかりやすいシステムです",
        desThird: "素晴らしい売買システム!",
        desFourth: "即座に対応してくれるカスタマーサポート",
        japanPrecious: "Japan Precious Summer 2021",
        jewelristVol157: "Jewelrist 2021 Vol.157",
        jewelristVol158: "Jewelrist 2021 Vol.158",
        nominee: "Top 100 tech Innovators & Influencers",
      },
    },
    login: {
      signInAuctioneer: "Sign in as Auctioneer",
      title: "Login",
      validation: {
        invalidEmailorPassword: "Invalid Credentials",
        newinvalidEmailorPassword:
          "Email or Password is not recognized or incorrect.",
        userNotRegistered: "User not registered",
        restrictedUserLine1:
          "We have restricted your access to MyAuctions.jp \n Please contact MyAuctions or mail us at contact@myauctions.jp",
        registeredAsAuctioneer: "This ID is registered as Auctioneer only",
      },
      labels: {
        email: "Email",
        password: "Password",
      },
      forgotPassword: "Forgot Password",
      dontHaveAccount: "Don't have an account",
      registerNow: "Register Now",
    },
    register: {
      title: "Register",
      occupation: {
        auctioneer: "Auctioneer",
        bidder: "Bidder",
        auctioneerAndBidder: "Auctioneer & Bidder",
      },
      labels: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        company: "Company",
        occupation: "Occupation",
        address: "Address",
        city: "City",
        country: "Country",
        state: "State",
        phone: "Phone",
        invitationCode: "Invitation Code",
      },
      placeholder: {
        firstName: "eg- Taro",
        lastName: "eg- Yamada",
        email: "eg- Taroyamada@abc.com",
        company: "eg- ABC Co. Ltd.",
        address: "eg- Ueno, 1-1-1 Building Name",
        city: "eg- Shibuya",
        country: "eg- Japan",
        state: "eg- Tokyo",
        phone: "eg- 1234567809",
        invitationCode: "eg- 123456",
        regexphone: "eg- 1234567809",
      },
      iAgree: "I Agree to the",
      robotText: "I’m not a robot.",
      buttonText: "Create Your Account",
      termsandCondition: "Terms of Service",
      and: "and",
      privacyPolicy: "Privacy Policy",
      alreadyAccount: "Already have an account?",
      login: " LOGIN",
      errorMessage: {
        onfirstName: "Please enter correct first name.",
        onlastName: "Please enter correct last name.",
        onCheckTerms: 'Please agree to "Terms of Services".',
        onMobileNumber: "Please enter correct mobile number.",
        onEmail: "Please enter correct email address.",
        newonEmail: "Your email is not recognized.",
        emptyEmail: "Please enter email address.",
        occupationNotSelect: "Please select Occupation.",
        alreadyRegistered: "There is already a user registered with the email",
        pendingRequest: "There is already a user in pending review",
        invitationCode: "Please use numeric character in inviatation code",
        phoneNumber: "Please enter valid phone number",
        notSeletBidder:
          "Please select occupation as “Bidder” to proceed with the registration using Invitation Code.",
        profileVerification:
          "Your profile is still under verification. We will send you an email on your registered email address once your profiles is verified",
        correctInvitationCode: "InvitationCode is not correct",
      },
      registerModal:
        "We have received your sign up request. Our team will contact you through email shortly.",
      subdoaminRegisterModal: "You are not registerd with Myauctions.",
      secondSubdoaminRegisterModal: "Please subscribe for complete access.",
    },
    profile: {
      title: {
        personalDetails: "PERSONAL DETAILS",
        bankDetails: "BANK DETAILS",
        shippingAddress: "SHIPPING ADDRESS",
      },
      legends: {
        email: "Email",
        companyName: "Company Name",
        contactNo: "Contact No.",
        modeOfContact: "Mode of contact",
        bankName: "Bank Name",
        accountHolderName: "A/C Holder Name",
        accountNo: "A/C Number",
        ifscCode: "IFSC Code",
        bankAddress: "Bank Address",
        timezone: "Timezone",
        companyAddress: "Company Address",
        guarantorName: "Guarantor Name",
        guarantorAddress: "Guarantor Address",
        referenceName: "Reference Name",
        referenceAddress: "Reference Address",
        editProfile: "EDIT PROFILE",
        refbankName: "Bank Name",
        refaccountHolderName: "Account Holder Name",
        refaccountNo: "Account Number",
        refifscCode: "Swift No",
        refbankAddress: "Bank Address",
        validation: {
          enterCompanyName: "Please enter company name",
          enterCompanyAddress: "Please enter company address",
          enterReferenceName: "Please enter reference name",
          enterReferenceAddress: "Please enter reference address",
          enterContactNo: "Please enter contact no.",
          enterContactNoVal: "Please enter valid contact no.",
          enterContactNoValregex:
            "Please enter valid contact no. eg- +1234567809",
          contactNoMustBe: "Contact number must be numeric",
          enterTimeZone: "Please select time zone",
          enterModeOfContact: "Please select mode of contact",
          enterCountry: "Please select country",
          enterCurrency: "Please select currency",
          enterContactNoValBtw:
            "Please enter valid 8 - 15 digit contact number",
        },
      },
      shippingAddress: {
        title: "SHIPPING ADDRESS",
        labels: {
          blockNo: "Block No.",
          streetName: "Street Name",
          city: "City",
          state: "State",
          country: "Country",
          company: "Company Name",
          address: "Address",
          phoneNo: "Phone No.",
          pinCode: "PinCode",
        },
      },
      bankDetails: {
        title: "BANK DETAILS",
        labels: {
          holderName: "Bank Holder Name",
          acNumber: "A/C Number",
          ifscCode: "IFSC Code",
          bankName: "Bank Name",
          bankAddress: "Bank Address",
        },
      },
      changePassword: {
        title: "CHANGE PASSWORD",
        labels: {
          oldPassword: "Old Password",
          newPassword: "New Password",
          confirmPassword: "Confirm Password",
        },
        validation: {
          enterOldPassword: "Please enter old password",
          enterNewPassword: "Please enter new password",
          enterConfirmPassword: "Please enter confirm password",
          incorrectOldPassword: "Incorrect old Password",
        },
      },
      forgotPassword: {
        title: "FORGOT PASSWORD",
        labels: {
          otp: "OTP",
          newPassword: "New Password",
          confirmPassword: "Confirm Password",
          email: "Enter your registered email id",
        },
        button: {
          update: "RESET PASSWORD",
          continue: "SEND OTP",
        },
        validation: {
          invalidOTP: "Invalid OTP!",
          newinvalidOTP: "The code you entered is incorrect.",
          correctOTP: "Please enter correct OTP",
          enterPassword: "Please enter password.",
          notRegisteredEmail: "Not a registered email Id",
        },
        note: {
          otpNote: "OTP has been sent to your email Id and contact number.",
          newOtpNote: "OTP sent to your email Id and contact number.",
          emailNote:
            "Enter your email address below and we will send an OTP to reset your password.",
        },
        onSuccessful: {
          passwordChange: "Password successfully changed.",
        },
      },
      changePlan: {
        receiveYourRequest: "We have received your request to",
        changeThePlan: '"Change the Plan"',
        getInTouch: "Our team will get in touch with you shortly.",
      },
      toast: {
        passwordUpdate: "Password updated successfully",
      },
      validation: {
        newAndConfirmPasswordNotMatch:
          "New password and confirm password does not match.",
        newAndOldPasswordNotMatch:
          "New password should be different from old password.",
      },
      optionMenu: {
        markAsDefault: "Mark as default",
        delete: "Delete",
      },
      addMore: "Add more +",
      userType: "User Type : ",
      startDate: "Start Date : ",
      endDate: "End Date : ",
      downloadInvoice: "Download Invoice",
    },
    preview: {
      products: "Products",
      bidButton: 'CLICK HERE TO ENTER',
      noProductFound: "No product found",
      noAuctionFound: "No auction available",
      addedToWatchList: "Watch",
      addedToWatchMsg: "Added into watchlist successfully",
      addWatchList: "UnWatch",
      carousalTitle: "Category",
      price: "Price",
      pieces: "Pcs.",
      ct: "CT",
      pol: "POL",
      sym: "SYM",
      at: "@",
      linkCopied: "Link copied",
      showAllProduct: "Show All Product",
      tendor: {
        live: "Live",
        popcornBidding: "Popcorn Bidding",
        allProducts: "All Products",
        myBids: "My Bids",
        timeLeft: "Time Left :",
        bulkBid: "Bulk Bid",
      },
      productsList: {
        startingPrice: "Starting Price",
        ro: "Rap off",
        preBiddingPrice: "Pre Bidding Price",
        serialNo: "S No.",
        pct: "Pct.",
        marqueeExchangeRate:
          "The exchange rate for invoicing will be same as the first day of preview=(1$= ",
        yen: "yen",
      },
      filterName: {
        shape: "SHAPE",
        color: "COLOR",
        clarity: "CLARITY",
        grading: "GRADING",
        price: "PRICE",
        weight: "WEIGHT",
        fancyColor: "FANCY COLOR",
        cutPolishSymmetry: "CUT POLISH SYMMETRY",
        cut: "CUT",
        startingPrice: "STARTING PRICE",
        jewellery: "Jewellery Type",
        jewelleryCondition: "Jewellery Condition",
      },
      productDetail: {
        itemDescription: "ITEM DESCRIPTION",
        share: "Share",
        copy: "Copy",
        viewFullScreen: "View Full Screen",
        shareTooltip:
          "User can share this product detail page with their colleague. Starting Price Pct., Pre-bid per carrot, Amount of starting price, price per carrot and rap % will not be visible in shared link.",
        watchTooltip:
          "You will receive an email 10 products before when your favourite product will appear in Live auction.",
        jewelleryShareTooltip:
          "User can share this product detail page with their colleague. Starting Price , Pre-bid per price , price will not be visible in shared link.",
        tendorshareTooltip:
          "User can share this product detail page with their colleague. Starting Price, Amount of starting price, price will not be visible in shared link.",
        tendorDiamondshareTooltip:
          "User can share this product detail page with their colleague. Starting Price Pct., Amount of starting price, price per carrot and rap % will not be visible in shared link.",
      },
      preBidding: {
        jpy: "000JPY",
        dollar: "0$",
        jpyJewellery: "JPY",
        preBidding: "Pre-bid per carat",
        enterAmount: "Enter price per carat",
        jwlEnterAmount: "Enter amount",
        validationOnCorrectAmount:
          "Pre bid amount should be greater than product's starting price",
        placeholderText: "Enter the amount of pre-bidding",
        preBidPopUpMessage: "Are you sure you want to Pre-bid with",
        JewPreBidding: "Pre-bid",
      },
      notes: {
        notes: "Notes",
        save: "Save",
        enterNote: "Enter Note",
        placeholderText: "Add a Note for product",
        notesValidation: "Characters should be less than 200 characters",
      },
      perItemDescription: {
        serialNo: "Serial No.",
        startingPrice: "Starting Price Pct.",
        auctionStartDate: "Auction Start Date",
        startDate: "Start Date",
        itemCode: "Item Code",
        carat: "Cts.",
        color: "Color",
        clarity: "Clarity",
        cutGrade: "Cut Grade",
        ex: "3EX",
        hnc: "HnC",
        flour: "Flour",
        lengthWidthHeight: "LxWxH",
        polish: "Polish",
        symmetry: "Symmetry",
        preBidAmount: "Pre-bid per carat",
        shape: "Shape",
        origin: "Origin",
        lab: "Lab",
        certificate: " Certi#",
        fancyIntensity: "Fancy Intensity",
        fancyColor: "Fancy Color",
        startingPricePerCarat: "Starting Price Per Carat",
        sellingPricePerCarat: "Selling Price Per Carat",
        amountOfStartingPrice: "Amount of Starting Price",
        pricePerCarat: "Price Per ct.(USD)",
        rap: "Rap%",
        rapPrice: "Rap Price",
        exchangeRate: "Exchange Rate",
        remarks: "Remarks",
        signInToView: "Sign in to View",
        noProductMessage: "The product has been removed by the Organizer.",
        sieve: "Sieve",
        noOfPieces: "No. of pieces",
        piecesPCT: "Pieces PCt.",
        pricePctUsd: "Price PCT (USD)",
        totalCts: "Total CTs",
        avgSize: "Avg Size",
        totalAmount: "Amount of Starting Price",
        productDescription: "Product Description",
        jewelleryStartingPrice: "Jewellery Starting Price",
        jewelleryType: "Jewellery Type",
        jewelleryCondition: "Jewellery Condition",
        jewelryPricePctUsd: "Price PCT (USD)",
      },
      tabLayout: {
        diamond: "Diamond",
        parcelItem: "Parcel Item",
      },
      filters: {
        cutFilter: {
          cut: "CUT",
          polish: "POLISH",
          symmetry: "SYMMETRY",
        },
        price: {
          ct: "/CT",
          total: "TOTAL",
        },
        fancyColor: {
          intensity: "INTENSITY",
          overTone: "OVERTONE/MODIFIER",
          color: "COLOR",
        },
        from: "From",
        to: "To",
      },
      modal: {
        participateDontHaveCode: "Don't have invitation code? ",
        forparticipation:
          "Just click on participate and we'll get in touch with you.",
        placeholderText: "INVITATION CODE",
        invitationCodeText:
          "Please enter invitation code to take part in the auction.",
        participateTextInvitee:
          "Your account is not enabled to access the other auctions. Our team will get back to you on this shortly.",
        participateText:
          "We have received your paticipation request. Our team will contact you through email shortly.",
        disableParticipation:
          "You can participate 1 hour before auction starts.",
      },
      search: {
        keyword: "Keyword",
      },
      pagination: {
        showing: "Showing",
        page: "Page",
        of: "of",
        results: "Results",
        lowestJpy: "Lowest JPY",
        highestJpy: "Highest JPY",
        lowestCarat: "Lowest Carat",
        highestCarat: "Highest Carat",
        itemsPerPage: "items per page",
        carat: "Carat",
        jpy: "JPY",
        myBids: "My Bids",
        lowestBids: "Lowest Bids",
        highestBids: "Highest Bids",
        export: "Export",
        inquiry: "Inquiry",
        select: "---Select---",
      },
    },
    home: {
      videoHeading: "HOW THE AUCTION WORKS",
      auctionHeading: "ONGOING AUCTION",
      calendar: "CALENDAR",
      advertisementHeading: "ADVERTISEMENT",
      upcomingAuctions: "Upcoming Auctions",
      requestAppointment: " Request Appointment",
      legend: {
        legend: "LEGEND",
        preview: "Preview",
        auction: "Auction",
        noAuction: "No Auction",
        appointment: "Appointment",
      },
      auctionDetails: {
        serialNo: "Serial Number -",
        timeLeft: "Time Left",
        startingPricePerCarat: "Starting Price",
        notes: "Notes",
        preBiddingPrice: "Pre-Bidding Price",
        rapnetPrice: "Rapnet Price",
        rapnetPercentage: "Rapnet Percentage",
        exchangeRate: "Exchange Rate",
        remarks: "Remarks",
        rapOff: "Rap Off",
      },
      modal: {
        soldOut: "Item Sold",
        at: "@",
        congratulations: "CONGRATULATIONS!",
        mazal: "MAZAL!!",
        text: "You have won the bid of",
      },
      heading: {
        auction: "AUCTIONS",
        preview: "PREVIEWS",
        fullDay: "Full Day",
      },
      list: {
        starting: "Starting",
        price: "Price",
        participate: "Participate",
      },
      noProductsAvailable: "No products available",
      downloadPdf: "Download HOW THE AUCTION WORKS PDF",
      previweNotStarted: "Preview not started",
    },
    invoice: {
      header:
        "Please note that below invoices are for FYI purpose. If you are a buyer outside Japan; kindly use the Custom clearance invoice for payments and courier services.",
      noInvoice: "No invoice",
      buttons: {
        shippingAddress: "Shipping Add.",
        download: "Download",
        issueInvoice: "Issue Import Invoice",
        trackingButton: "Tracking",
        invoiceButton: "Invoice",
      },
      heading: {
        date: "DATE",
        totalInvoice: "TOTAL INVOICED",
        totalAmountToPay: "Total Amount to pay",
        totalAmountToReceive: "Total Amount to Receive",
      },
      filter: {
        search: "Search",
        startDate: "Select Start Date",
        endDate: "Select end Date",
      },
      validation: {
        selectAddress: {
          firstLine:
            "Please select an address where you want the delivery to be shipped.",
          secondLine:
            "If no address is selected, then delivery will be done at your default shipping address.",
          thirdLine:
            "NOTE - You can add or change the shipping address through profile page.",
        },
        noShippingAddressFound:
          "No Shipping Address found. You can add it through profile page.",
      },
      tracking: {
        recipientLable: "Issue Buyer Invoice",
        invoiceButton: {
          trackYourPackage: "Track your Package",
          saveDetails: "Save Details",
        },
        trackingForm: {
          shippingCompanyName: "Shipping Company",
          awbTrackingNo: "Enter AWB Tracking Number",
          detailsSubmit: "Your information has been submitted successfully.",
        },
        buyerDetails: {
          companyName: "Name",
          country: "Country",
          deliveryAddress: "Delivery Address",
          RecipientName: "Recipient",
        },
        validation: {
          companyNameValidation: "Please select Company Name.",
          awbNumberValidation: "Please Enter Your AWB Tracking Number.",
        },
      },
    },
    auction: {
      auctionEnded: {
        firstLine: "Thank you ",
        secondLine: "for participating in the Auction !!",
        thirdLine: "See you soon.",
      },
      auctionHold: {
        firstLine: "Your product is on Hold.",
        secondLine:
          "We will let you know about the product sale within 2 working days over email.",
      },
      auctionPaused: {
        firstLine: "Auction is paused.",
        secondLine: "Auction is paused due to technical reason",
        thirdLine: "Please wait while we resume.",
      },
      auctionAboutToStart: {
        aboutToStart: "Auction is about to start",
      },
      auctionCancelled: {
        firstLine:
          "We have ended the Auction due to some technical glitch in the system.",
        secondLine: "Apologies for the inconvinience.",
      },
      notAbleToStart: "Not able to start",
      technicalGlitch: {
        firstLine: "Auction cannot start due to some technical glitch.",
        secondLine: "Please contact respective auctioneer for the update.",
      },
      connectionLost: {
        firstLine: "Connection lost.",
        secondLine: "Please refresh the page or check internet connection.",
      },
      automaticClock: {
        firstLine: "Your system timezone is not in automatic mode.",
        secondsLine:
          "You might face delay of few seconds in the website. To avoid this, please set the system timezone in automatic mode.",
      },
      sellerMessage: {
        firstLine: "Bidding Restricted.",
        secondLine: "You are the seller of this product. ",
      },
      pasuedModal: {
        headerfirstLineFirst: "Note: The auction will resume in: ",
        headerfirstLineSecond: "after product no.",
        headerSecondLine: "Take time to Chill!",
        biddingCont: "Bidding Countinues in",
      },
      tenderExtendedTime: {
        firstLine: "It's Not Over Yet ",
        secondLine: "Products which got bid in the last 15 mins have been",
        thirdLine: "given an extended time of 30 mins",
        lastLine: "Keep Bidding !!",
      },
    },
    watchList: {
      watchlistEmpty: "watchlist is empty",
    },
    allValidations: {
      participate: {
        noAuctionFound: "No Auction Found",
        mailSentFailed: "Mail Sent Failed",
        alreadySentParticipationMail: "Participation Mail Sent Already",
        alreadySentInviationMail: "Invitation mail already sent",
      },
      join: {
        invalidCodeorMail: "Invalid Code or Mail not receive",
        invalidCode: "Invalid Code",
        enterInvitationCode: "Please enter invitation code",
      },
      bank: {
        accountHolderName: "Please enter account holder name",
        accountNumber: "Please enter account number",
        ifscCode: "Please enter ifsc code",
        bankName: "Please enter bank name",
        address: "Please enter address",
      },
      shipping: {
        blockNo: "Please enter block no",
        address: "Please enter address",
        streetName: "Please enter street name",
        city: "Please enter city",
        state: "Please enter state",
        country: "Please enter country",
        company: "Please enter company",
      },
      emptyField: "Please fill required field",
      emptyFields: "Please fill required fields",
    },
    timeOut: {
      sorry: "Sorry",
      somethingWrong: "Something went wrong",
      tryAgain: "Please try again",
    },
    pageNotFound: {
      oops: "OOPS",
      sorryPageNotFound:
        "Sorry, the page you are looking for could not be found",
      goToHome: "Go to Home page",
    },
    paymentAgreement: {
      fourModal: {
        paymentAgreementInEn: `All matters in question shall be investigated by Bidder prior to
              purchase agreement, after which the transaction is final and
              irreversible, except in cases such as treatment in the diamond
              that has not been disclosed or doubts regarding the Origin (
              Natural / Laboratory grown / Coating etc. ) of the diamond.`,
        paymentAgreementInJp: `すべての懸念事項は、購入契約の前に入札者が調査するものとします。その後、取引は最終的で不可逆的に成立するものとします。ただし、開示されていないダイヤモンドの処理（人工ダイヤ/コーティングなど）に関する疑いがある場合を除きます
              。`,
        paymentAgreementInZh: `投标人应在达成购买协议之前看清所有相关事宜，交易后不可撤销，除未披露钻石的处理方式或对原产地（天然/实验室培育/涂层等）有疑问
              的钻石。`,
      },
      secondModal: {
        paymentAgreementInEn: `Payment for the generated invoice is due within 3 business days of
              notice via bank Wire. Failure to submit payment by the due date
              will result in a late fee of 1% and could result in additional
              handling fees.`,
        paymentAgreementInJp: `オークション終了後に作成された請求書の支払い期限は、
              銀行振込による通知から 3
              営業日以内です。期日までに支払いを提出しないと、1%
              の遅延料金が発生し、追加の手数料が発生する可能性があります。`,
        paymentAgreementInZh: `发付款应在收到发票通知后 3
              个工作日内通过银行电汇支付。未能在到期日之前付款将导致 1%
              的罚金，并可能导致额外的手续费。`,
      },
      thirdModal: {
        paymentAgreementInEn: `Freight charges, custom charges and any other additional costs are
              born by bidder. Any discrepancies between the merchandise listed
              on the invoice and the actual merchandise received must be
              reported to within 24 hours of receipt.`,
        paymentAgreementInJp: `実際に御受け取りいただいた商品が請求書に記載の商品と異なる場合にはお受け取りから24時間以内に必ずご連絡ください。
        `,
        paymentAgreementInZh: `运费、海关费用和任何其他额外费用由投标人承担。发票上列出的商品与收到的实际商品之间的任何差异必须在收到后
              24 小时内报告。`,
      },
      firstModal: {
        paymentAgreementInEn: `Please be advised that a buyer commission of 3% will be applicable on the final purchase invoice.`,
        paymentAgreementInJp: `最終的な購入請求書には3％のバイヤー手数料がかかることをご了承ください。`,
        paymentAgreementInZh: `请注意，最终购买发票将收取 3% 的买家佣金。`,
      },
    },
    ongoing: "Ongoing",
    paused: "Paused",
    break: "Break",
    willResume: "Will Resume In",
    abouttoStart: "About to start",
    invalidRequest: "Invalid request.",
    restrictToJoinAuction:
      "To join the auction, please set your machine's clock in automatic mode.",
    warning: {
      firstLine: "Warning !!",
      secondLine: "Please set your machine's clock in automatic mode.",
    },
    somethingWentWrong:
      "Something went wrong. Please wait for a while and try again.",
    kycFrom: {
      kycFromHeading: "KYC Form",
      name: "Name",
      fName: "First Name",
      lName: "Last Name",
      line: "Line",
      whatsup: "Whatsapp",
      weChat: "WeChat",
      sms: "SMS",
      facebook: "FB",
      swiftNo: "Swift No",
      modeOfCommunication: "Mode of communication",
      japan: "Japan",
      other: "Other",
      knowKobustuLicense: "Do you have kobustu License?",
      kobustuLicenceQuestion:
        "Do you have a Kobutsu License/or alliance company in Japan having Kobutsu Licence? ",
      guaranterCompany: "Guarantor",
      companyDetail: "Company Details",
      contactPerson: "Contact Person",
      attachFiles: "Attach Files",
      kobustuLicense: "Kobustu Licence",
      uploadFiles: "Upload Files",
      indenfyCertificate:
        "Identity Certificate with photo (Password: Alien Card, Divers Licence, My No. Card)",
      passbookCopy: "Passbook Copy",
      tokiboTohon: "Tokibo Tohon",
      shippingAddressCapitalize: "Shipping Address",
      billingAddress: "Billing Address",
      submit: "SUBMIT",
      yes: "Yes",
      no: "No",
      bankDetails: "Bank Details",
      blockNumber: "Block Number",
      streetName: "Street Name",
      city: "City",
      state: "State",
      subDomain: "Sub Domain",
      samePersonalDetail: " Same As Personal Details",
      sameBillingAddress: " Same As Billing Address",
      preferredLanuage: "Preferred Language",
      preferredCurrency: "Preferred Currency",
      currency: "Currency",
      companyLogo: "Company Logo",
      document: "Document",
      shouldBeLess: "should be less than 10 MB",
      natureofBusiness: "Nature of Business",
      kycModal: {
        firstLine: "Thank you for submitting the form",
        secondLine: "Our team will get back to you shortly",
      },
      businessRegistration: {
        headingName: "Business Details",
        heading:
          "Business Registration Number / Incorporation Certificate ­OR A License or Evidence, which entitled you to run this Particular Business",
        dateOfIncorporation: "Date Of Incorporation",
        iCNumber: "Incorporation Certificate Number",
      },
      saveAsDraft: "SAVE AS DRAFT",
      companyDoc: "Company Registration Document ",
      bankDoc:
        "Bank Document (Any document issued by bank verifying the bank details entered: Example: Passbook cover page, Cancelled cheque etc)",
      bankDocHeading: "Bank Document",

      errorMessage: {
        onFName: "Please fill first name",
        onLName: "Please fill last name",
        onCompanyName: "Please fill company name",
        onShippingAdd: "Please fill shipping address",
        onBillingAddress: "Please fill billing Address",
        onBankName: "Please fill bank name",
        onBankAddress: "Please fill bank address",
        onAccHolderName: "Please fill account holder name",
        onSwiftNo: "Please fill swift number",
        onAccountNo: "Please fill account number",
        onCountry: "Please fill country",
        onKobustuLic: "Do you have kobustu License ?",
        onPreferredLanguage: "Please select preferred language",
        onPreferredCurrency: "Please select preferred currency",
        onModeOfComm: "Please select mode of communication",
        onContactPerson: "Please fill contact person",
        onKobustuLicense: "Please upload kobustu License",
        onTokiboTohon: "Please upload Tokibo Tohon",
        onPassbookCopy: "Please upload Passbook Copy",
        onIdentityCertificate: "Please upload Identity Certificate",
        onCompanyLogo: "Please upload company logo",
        onSelectKobustuLicense: "Do you have Kobustu License or not ?",
        onGuarantorCompanyName: "Please fill guarantor company name",
        onGuarantorContactPerson: "Please fill guarantor contact person",
        onGuarantorEmail: "Please enter correct guarantor email address",
        onGuarantorContactNumber: "Please enter valid guarantor contact no",
        onGuarantorMobileNumber:
          "Please enter correct guarantor contact number",
        onGuarantorBillingAddress: "Please fill guarantor billing Address",
        onGuarantorShippingAdd: "Please fill guarantor shipping address",
        onGuarantorCountry: "Please fill guarantor country",
        onGuarantorAccHolderName: "Please fill guarantor account holder name",
        onGuarantorAccountNo: "Please fill guarantor account number",
        onGuarantorBankName: "Please fill guarantor bank name",
        onGuarantorBankAddress: "Please fill guarantor bank address",
        onReferenceCompanyName: "Please fill Reference company name",
        onReferenceContactPerson: "Please fill Reference contact person",
        onReferenceEmail: "Please enter correct Reference email address",
        onReferenceContactNumber: "Please enter valid Reference contact no",
        onReferenceMobileNumber:
          "Please enter correct Reference contact number",
        onReferenceBillingAddress: "Please fill Reference billing Address",
        onReferenceShippingAdd: "Please fill Reference shipping address",
        onReferenceCountry: "Please fill Reference country",
        onReferenceAccHolderName: "Please fill Reference account holder name",
        onReferenceAccountNo: "Please fill Reference account number",
        onReferenceBankName: "Please fill Reference bank name",
        onReferenceNatureOfBusiness:
          "Please fill Reference Nature Of Businesse",
        onReferenceDORC: "Please enter Date Of Registration / Certificate",
        onReferenceNOYB: "Please enter Refrence No Of Years in Bussiness",
        onICN: "Please enter Incorporation Certificate Number",
        onBankDocument: "Please upload Bank Document",
        onCompanyDocument: "Please upload Company Document",
        saveAsDraft: "Your form has been submitted as draft.",
      },
      previous: "Previous",
      next: "Next",
      faq: {
        heading: "FAQ",
        faq: "Frequently Asked Questions",
        myAuction: {
          question: "What are My Auctions?",
          answer:
            "My Auctions is a rental portal where in Auctioneers can register themselves and create their own personalized auctions, while bidders can pick the auctions, they wish to participate in.",
        },
        privacyInsured: {
          question: "What about Privacy Insurance?",
          answer: {
            firstLine:
              "My Auctions is a platform established solely for the purpose of introducing the Auctioneer’s goods to the Bidder in the best possible way so that Bidders have full transparency and disclosure of the condition and specifications of goods offered.",
            secondLine:
              "No personal information is shared with any third-party online advertiser or website or app as part of any such activity. My Auctions does not provide any information relating to your usage to such website operators or network advertisers.",
          },
        },
        whoIsAuctioneer: {
          question: "Who is an Auctioneer?",
          answer:
            "Anyone who wants to sell (hereby Auction) his goods like Diamonds, Jewellery bags et al. is an Auctioneer. As little has 10 goods; as much as 1000000 goods! Schedule your auction with us. ",
        },
        whoIsBidder: {
          question: "Who is a Bidder?",
          answer:
            "Anyone who wants to buy (hereby in Auction) goods like Diamonds, Jewellery bags et al. is a bidder. ",
        },
        howAuctioneerCreateAuctionWebsite: {
          question: "How can Auctioneer create Auction website?",
          answer: {
            firstLine:
              "Auctioneer can choose whether they want to conduct auction ",
            points: {
              firstPoint:
                "1. With their name For example: www.abcauction.myauctions.jp",
              secondPoint:
                "2. Without their name For Example: www.myauctions.jp",
            },
            secondLine: "(Charges levied shall change accordingly)",
            thirdLine:
              "The subdomain for category 1 is asked while registration. ",
          },
        },
        documentRequiredForRegisteration: {
          question: "What are the documents required for registration?",
          answer: {
            firstLine:
              "The following documents are required for conducting/attending Auctions in Japan.",
            points: {
              firstPoint: "1. Kobutsu License",
              secondPoint: "2. Company Registration Paper (Tokibo/Tohon)",
              thirdPoint:
                "3. Identity Certificate with photo (Driving License/Residence Card/My Number)",
              fourthPoint:
                "4. Financial Copy (Copy of passbook/Bank Statement)",
            },
            secondLine:
              "For Bidders outside Japan they would require a Guarantor company in Japan. ",
            thirdLine:
              "he goods shall be billed on the company details of the guarantor provided by the Off-Shore Buyers. Thereafter exporting et. al.  does not come under the purview of My Auctions or auctioneer at all.",
            fourthLine:
              "Though My Auctions verify the company thoroughly before onboarding but we still request the respective auctioneers to do the thorough verification.",
          },
        },
        returnToWebsite: "Return to website",
      },
    },
    blockUserPopup: {
      firstLine: "We have restricted your access to MyAuctions.jp.",
      secondLine:
        "Please contact MyAuctions or mail us at contact@myauctions.jp",
    },
    paymentForm: {
      securePaymentGateway: "Secure Payment Gateway",
      payNow: "Pay Now",
      amountToPay: "Amount to Pay",
      paymentSuccessful: "Payment Successful",
      paymentSuccessfullMessage:
        "You will receive the login Credential shortly.",
      paymentFailed: "Payment Failed",
      paymentFailedMessage:
        "Either your card details are incorrect or it is not authorized for such transactions. Please try again and if you still face issue, kindly contact administrator.",
    },
    aboutUs: {
      newPage: {
        meetTeam: "Meet Our Team",
        heading: "Driven by Customer Satisfaction.",
        heading1: "Problem-solvers at Heart.",
        subheading: "Consistency and perseverance.",
      },
      founder: {
        name: "Monika Jain",
        title: "Founder & CEO",
        founderAndCEO: "Founder & CEO Monika Jain",
        founderFirstText:
          "Monika Jain is an Indian civil engineer and serial entrepreneur working in Japan. Jain served as a valuable member of the Global Shaper community at the World Economic Forum.",
        founderSecondText:
          "Today, Jain provides startups with her utmost knowledge, network, and problem-solving skills. She works with She loves tech, Google for Startups, 500 Global, and AWS Startups to support working mothers.",
        founderThirdText:
          "Jain is also on the board of directors at MJ Diamonds. Co. Ltd. and GPB Infraprojects Pvt Ltd. My Auctions is her fourth startup based in Tokyo.",
      },
      company: {
        companyProfie: "Company Profile",
        tradeLabel: "Company Name",
        tradeValue: "My Auctions",
        HeaderQuaLabel: "Headquarters address",
        HeaderValue: "201 First Building, 3-10-1 Ueno Taitou Ku Tokyo 1100005",
        EstablishedLabel: "Establishment",
        EstablishedValue: "August 5, 2020",
        CEOLable: "CEO",
        CEOValue: "Monika Jain",
        mainBusinessLabel: "Business Area",
        mainBusinessValue: "Auctions Rental Portal",
        corporateLabel: "Corporate Number",
        corporateValue: "0105-01-046069",
        bankLabel: "Bank",
        bankValue: "Asahi Shinkin Bank",
      },
      mission: {
        missionLabel: "MISSION ",
        missionValue:
          "Making Auctions fair, easy and equally accessible for all.",
      },
      coFounders: {
        coFounder: "Co-Founder",
        kanishk: {
          firstLine: "KANISHK CHORARIA",
          secondLine: "Mr. Foot",
          thirdLine:
            "More than 12 years of industry experience, nominated for Top 50 Tech Leaders Award by InterCon. Always brings innvoative solutions to industry by his organisations Dexterity and Excellence",
        },
        pradeep: {
          firstLine: "PRADEEP SHARMA",
          secondLine: "CTO",
          thirdLine:
            "Pradeep Sharma is a senior software engineer with 20 years of technical expertise. He worked as a technical lead and an IT project manager. At My Auctions, Sharma manages cybersecurity and website interface.",
        },
        haruka: {
          firstLine: "HARUKA ARAI",
          secondLine: "COO at Zipang Auctions, Japan Pawn Auction",
          thirdLine:
            "The auction will be working under the expert guidance of 新井春芽 ,Arai San ,  Ex president of NJKP Auction. Getting to learn from his 40 years of veteran experience in the Japan Auction Industry, we couldn't be more fortunate!",
        },
        shobhit: {
          firstLine: "SHOBHIT SUCHANTI",
          secondLine: "Mr. soul",
          thirdLine:
            "Shobhit Suchanti is a GIA diamond-qualified merchant. Suchanti has a long history of 10 years in the Japanese auction industry. Here, he mastered importing and exporting diamonds to Japan. Today, Suchanti is a domain expert at My Auctions.",
        },
      },
    },
    mjDiamonds: {
      banner: {
        companyName: "MJ Diamonds",
        companyDescription: "The Genius Way to Buy The Diamonds",
        button: "Start Bidding",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "Committed to providing our customers with the best value, quality service and wide selection of  Diamonds from Japan; purchased through an in-house network.. All your Diamond and jewelry needs can be found here with us. Infused with faith, our work environment, processes and output are aimed at building abiding trust amongst customers, employees, bankers, suppliers, well-wishers and all belonging to our larger ecosystem; To maintain highest degree of transparency, honesty and business ethics at all levels.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Loose Diamonds & Jewellery",
        auctionDescription:
          "One of Japans leading auction with a wide variety of high quality items purchased through an in-house network. We offer auctions with an impressive lineup of the maximum variety of Diamonds from Japan to the world.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    subDomain: {
      banner: {
        companyName: "Your Company Name",
        companyDescription:
          "An interactive tagline that describes your company or its profile in approximately 20-30 words, will be updated here.",
        button: "Start Bidding",
      },
      daimodsInfo: {
        watches: "Watches",
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "Descriptions of the goods present in your auction will be showcased in this section. These goods can be: Imported diamonds, recycled diamonds, parcel items etc. In case of multiple categories, you can provide description for all in approx. 100 words. Ex. Imported diamonds, recycled diamonds, parcel items, Jewelry, watches etc.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Auction Goods",
        auctionDescription:
          "Descriptions of the goods present in your auction will be showcased in this section. These goods can be: Imported diamonds, recycled diamonds, parcel items etc. In case there are multiple categories of goods, you can provide description for all in Approx. 100 words. Ex. Imported diamonds, recycled diamonds, parcel items, Jewellery, bags, watches etc.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Descriptions of the people working together for abc company can be represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    okura: {
      banner: {
        companyName: "Okura Co., Ltd.",
        companyDescription: '"Make the world fun by reuse."',
        button: "Start Bidding",
      },
      daimodsInfo: {
        watches: "Watches",
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
        watchlist: "watches",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "We are engaged in wholesale and sales of old goods such as watches, bags and precious metals in Japan and overseas, and special event sales and auction business of international jewelry.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Auction Goods",
        auctionDescription:
          "We are exhibiting brand watches, brand jewelry, non-brand jewelry, and loose diamonds. Brand jewelry Cartier, Van Cleef & Arpels, Tiffany, Bvlgari etc. For brand watches, Rolex, Omega, etc.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Established Okura Co., Ltd. July 1, 2019 Representative Director Masaya Miki Office 〒812-0893 4-20-33 Naka, Hakata-ku, Fukuoka City, Fukuoka Prefecture Antique Business Law License: Fukuoka Prefectural Public Safety Commission Antique Dealer License No. 901021910081 Fukuoka Prefectural Public Safety Commission Antique Market Owner License No. 901011920001",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O:  03-5829-9622",
        faxNumber: "Fax: 03-5829-9652",
        officeAddress:
          "2-4-10 Iwamotocho, Chiyoda-ku, Tokyo 101-0032 Odakyu Kanda Iwamotocho Building 3F, 4F",
        emailId: "-",
      },
    },
    nihonauction: {
      banner: {
        companyName: "nihonauction",
        companyDescription: "The Genius Way to Buy The Diamonds",
        button: "Start Bidding",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "Committed to providing our customers with the best value, quality service and wide selection of  Diamonds from Japan; purchased through an in-house network.. All your Diamond and jewelry needs can be found here with us. Infused with faith, our work environment, processes and output are aimed at building abiding trust amongst customers, employees, bankers, suppliers, well-wishers and all belonging to our larger ecosystem; To maintain highest degree of transparency, honesty and business ethics at all levels.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Loose Diamonds & Jewellery",
        auctionDescription:
          "One of Japans leading auction with a wide variety of high quality items purchased through an in-house network. We offer auctions with an impressive lineup of the maximum variety of Diamonds from Japan to the world.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    Tender: {
      tableColumnNames: {
        serialNo: "S. No.",
        productId: "Product Id",
        Itemcode: "Item Code",
        sku: "SKU",
        lab: "LAB",
        certification: "Certification",
        description: "Description",
        pricePct: "Price Pct",
        jewelleryPrice: "Starting Price",
        yourBiddingPrice: "Your Bidding Price",
        notes: "Notes",
        liveHighestBid: "Live Highest Bid",
        rapOff: "Rap Off",
        noOfBidders: "No Of Bidders",
        timeLeft: "Time Left",
        remark: "Remarks",
        startingPrice: "Starting Price",
        Sieve: "Sieve",
        ShowAll: "Show All",
        Doyouwishtobid: "Do you wish to bid:",
        ActionsTender: "Action",
        carat: "Carat",
        color: "Color",
        clarity: "Clarity",
        shape: "Shape",
        cutGrade: "Cut Grade",
        noOfPieces: "No Of Pieces",
        polish: "Polish",
        symmetryAbbreviation: "Symmetry",
        flour: "Flour",
        yourBid: "Your Bid :",
        bulkBid: "Bulk Bid",
        details: "Details",
        yourBidPrice: "Your Bid Price",
        placeBid: "Place Bid",
      },
      rapOff: "Rap Off",
      validation: {
        productSelection: "Please select only 10 products at a time.",
      },
      bidInfo: {
        bidDescription: "Do you wish to bid:",
      },
      button: {
        showAll: "Show All",
        placeBid: "Place Bid",
      },
    },
    selby: {
      banner: {
        companyName: "Selby",
        companyDescription: "The Genius Way to Buy The Diamonds",
        button: "Start Bidding",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "Committed to providing our customers with the best value, quality service and wide selection of  Diamonds from Japan; purchased through an in-house network.. All your Diamond and jewelry needs can be found here with us. Infused with faith, our work environment, processes and output are aimed at building abiding trust amongst customers, employees, bankers, suppliers, well-wishers and all belonging to our larger ecosystem; To maintain highest degree of transparency, honesty and business ethics at all levels.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Loose Diamonds & Jewellery",
        auctionDescription:
          "One of Japans leading auction with a wide variety of high quality items purchased through an in-house network. We offer auctions with an impressive lineup of the maximum variety of Diamonds from Japan to the world.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    dialuster: {
      banner: {
        companyName: "Dialuster",
        companyDescription: "Nothing is impossible",
        button: "Start Bidding",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "Committed to providing our customers with the best value, quality service and wide selection of  Diamonds from Japan; purchased through an in-house network.. All your Diamond and jewelry needs can be found here with us. Infused with faith, our work environment, processes and output are aimed at building abiding trust amongst customers, employees, bankers, suppliers, well-wishers and all belonging to our larger ecosystem; To maintain highest degree of transparency, honesty and business ethics at all levels.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Loose Diamonds & Jewellery",
        auctionDescription:
          " Under the guidance of Masamori motto, we have recently started with our auctions with an impressive lineup of the maximum variety of Diamonds and Jewelry from Japan to the world.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 03-6421-8885",
        faxNumber: "Fax: 055-223-0211 ",
        officeAddress: "5-16-17 Ueno Taitou Ku Tokyo",
        emailId: "dialuster@gmail.com",
      },
    },
    kiraga: {
      banner: {
        companyName: "Jewelry Maker - KIRAGA",
        companyDescription: "The Genius Way to Buy The Diamonds",
        companyDescriptionNew:
          "A jewellery manufacturer located in Sunto-gun, Shizuoka Prefecture. We manufacture, process, wholesale and retail jewellery. Please visit the salon on the hill of Clematis. We also have an exhibition.",
        button: "Start Bidding",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "A jewelry manufacturer in Sunto-gun, Shizuoka Prefecture, we manufacture, process, nationwide wholesale, and retail jewelry.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Loose Jewellery",
        auctionDescription:
          "One of Japans leading auction with a wide variety of high quality items purchased through an in-house network. We offer auctions with an impressive lineup of the maximum variety of Diamonds from Japan to the world.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          'We are waiting for your visit with our carefully selected jewelry, open garden, skilled craftsmen and staff who shape the thoughts of our customers. When you hear the word "jewelry store", you may think it',
        relevantDescriptionAdd:
          "'s a little difficult to enter, but please feel free to come and visit us.",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 055-980-1888",
        faxNumber: "Fax: - ",
        officeAddress:
          "121, 608 Higashino, Nagaizumi-cho, Sunto-gun, Shizuoka Prefecture",
        emailId: "",
      },
      releventHeader: {
        relevantHeader: "It is a cheap auction sponsored by a jewellery maker.",
        firstdescription:
          "It will be an invitation-only auction, if you wish to participate ",
        seconddescription: "To DM on Twitter or e-shop@kiraga.co.jp",
        thirdDescription:
          "Please contact me. The auction will be held on Twitter or Please contact me. The auction will be held on Twitter or we are announcing it on Instagram.",
        descriptionAdd:
          "Please note that a consumption tax will be required in addition to the winning bid when making a successful bid.",
      },
    },
    takeyama: {
      banner: {
        companyName: "Takeyama Co., Ltd.",
        companyDescription:
          "Since 1947, we have been manufacturing diamond and colored stone jewelry in Okachimachi, Tokyo.",
        button: "Start Bidding",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "Since 1947, we have been manufacturing diamond and colored stone jewelry in Okachimachi, Tokyo.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Loose Jewellery",
        auctionDescription:
          "One of Japans leading auction with a wide variety of high quality items purchased through an in-house network. We offer auctions with an impressive lineup of the maximum variety of Diamonds from Japan to the world.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Descriptions of the people working together for MJ Diamonds can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
      footer: {
        company: "MY AUCTIONS",
        copyright: "2020-21 All Rights Reserved. ",
        officeNumber: "O: 03-3834-5050",
        faxNumber: "Fax: - ",
        officeAddress: "1 Chome-17-3 Higashiueno, Taito City, Tokyo 110-0015",
        emailId: "",
      },
    },
    inquiry: {
      support: "SUPPORT",
      Inquiry: "INQUIRY",
      button: {
        submit: "SUBMIT",
        Reply: "REPLY",
        bidNow: "Bid Now",
      },
      submitQueriy: "Submit your Query",
      submitDescription:
        "Write your Query about My Auctions that you wish to inquire for",
      singleProduct: "Place your query for single product here.",
      typeMessege: "Type a message",
      placeYourQuery: "Place your query here.",
      fields: {
        Section: "SECTION",
        Status: "STATUS",
        Action: "ACTION",
        Pending: "PENDING",
        Replied: "REPLIED",
        Resolved: "RESOLVED",
        Product: "PRODUCT",
        Other: "OTHER",
        Query: "QUERY",
        Attachment: "ATTACHMENT",
        AttachmentLink: "ATTACHMENT LINK",
        ChooseFile: "Choose File",
      },
      toastString: {
        messageSent: "Message Sent",
      },
      popupString: "Will get back to you soon within 24 hours.",
      popupStringFirst: "We have received your query regarding My Auctions.",
      inquiryText:
        "Welcome to My Auctions Inquiry space. If you have any queries, we are online and available to chat.",
    },
    toastTraslation: {
      ParticipationMailAlreadySent: "Participation Mail Already Sent",
      AddedIntoWatchlistsuccessfully: "Added into watchlist successfully",
      Removedfromwatchlistsuccessfully: "Removed from watchlist successfully",
      Notesaved: "Note saved successfully",
      PrebidPriceSavedSuccessfully: "Pre-bid price saved successfully",
      PreBidPriceRemovedsuccessfully: "Pre-bid price removed successfully",
      Operationnotallowed: "Operation not allowed",
      messageSent: "Message Sent",
      replyText: "Please Enter Message",
    },
    hokuyu: {
      banner: {
        companyName: "Hokuyu",
        companyDescription: "Recycled Jewelry direct from Japan",
        button: "Start Bidding",
      },
      daimodsInfo: {
        daimond: "DIAMONDS",
        auction: "AUCTIONS",
        jewellery: "JEWELLERY",
      },
      companyInfo: {
        about: "About",
        companyDescription:
          "Committed to providing our customers with the best value, quality service and wide selection of jewelry from Japan; purchased through an in-house network.. All your jewelry needs can be found here with us. Infused with faith, our work environment, processes and output are aimed at building abiding trust amongst customers, employees, bankers, suppliers, well-wishers and all belonging to our larger ecosystem; To maintain highest degree of transparency, honesty and business ethics at all levels.",
      },
      auctionInfo: {
        auction: "AUCTIONS",
        importantDaimond: "Loose jewelry",
        auctionDescription:
          "One of Japans leading auction with a wide variety of high quality items purchased through an in-house network. We offer auctions with an impressive lineup of the maximum variety of Jewellery from Japan to the world.",
      },
      relevantInfo: {
        meetRelevant: "Meet Relevant People",
        relevantDescription:
          "Descriptions of the people working together for Hokuyu can re represented here. They can provide multiple images that will be visible (as appearing in left side) in the form of a collage.",
      },
    },
    network: {
      compeleteProfile: "Please complete your profile",
      internetConnection:
        "Please check your internet connection and refresh the page",
    },
    FreePersonalizedWebsites: {
      text: {
        textfirst: "Free Personalized Websites",
        textsecond: "Online auctions is no more a thing of past.",
        textSecondOne:
          "Get end to end solutions for your online auction today!",
        third:
          "When you're looking to advance to the next level, we've got the",
        four: "platfrom to extend your business.",
      },
      CreateWebSite: "Create Your Free Website",
      CreateWebSiteSecond: "Create Free Auction Website",
      sliderText: {
        textFirst:
          "Looking for a platform that fulfills your needs for an auction website.",
        textSecond: "Build smarter workflows that make decision for you.",
        textThird:
          "My Auctions features you with creating your own free personalized website at one click.",
      },
      websiteForm: {
        EnterDetails: "Enter your Details",
        Name: "Name",
        CompanyName: "Company Name",
        Address: "Address",
        ChooseFile: "ChooseFile",
      },
    },
    pendingProduct: {
      HeaderName: {
        productId: "Product Id",
        biddingPrice: "Bidding Price",
        sellingPrice: "Selling Price",
        description: "Description",
        negotiate: "Negotiate",
        auctionName: "Auction Name",
      },
      button: {
        negotiate: "Negotiate",
        yes: "YES",
        no: "NO",
        cancel: "CANCEL",
        ok: "OK",
      },
      text: {
        DoYouWishNegotiatePopupFirst:
          "Do you wish the product to be sold to you",
        DoYouWishNegotiatePopupSecond: "on the selling price.",
        descriptionFirstText:
          "Please enter the negotiation price for the product with",
        descriptionSecondText: "having current bid price",
        descriptionThird: " and selling price is  ",
        enterNegotiateValue: "Enter the negotiation price",
        enterNegotiateValuegreater:
          "Negotiation Price should be greater than bidding price",
      },
      modal: {
        winnerText: "Product Sold on Selling Price",
      },
    },
    newLandingPage: {
      signUp: {
        heading:
          "Get Access to All Auctions and Create Unlimited Ones for FREE",
        subHeading: "Start your 14 days trial",
        buttonText: "Start Free Trial",
        formHeading: "Sign up to Start",
        formHeading2: "Bidding Soon.",
        errorMessage: "Please fill in the required information.",
      },
      otpText: "Please Enter code sent to you for verification.",
    },
    newCommanTrans: {
      Seller: "Seller",
      Bidder: "Bidder",
      Cart: "Cart",
      Itemcode: "Item Code",
      Date: "Date",
      preBiddingprice: "Pre-bidding price",
      productdescription: "Product description",
      state: "State",
      startingprice: "Starting price(PCT.)",
      sellingprice: "Selling price",
      finalbidprice: "Final bid price",
      SKU: "SKU",
      totalAmount: "Total Amount",
      rowsperpage: "Rows per page",
      hold: "Hold",
      sold: "Sold",
      productID: "Product ID",
      notificationdate: "Notification date",
      auctionname: "Auction name",
      notificationtype: "Notification type",
      description: "Description",
      notifications: "Notifications",
      soldproduct: "Sold_product",
      customMessage: "Custom_Message",
      deleteProduct: "Delete_Product",
      holdProduct: "Hold_Product",
      outBid: "Out_Bid",
      filters: "Filters",
      all: "All",
      reset: "Reset",
      next: "Next",
      selectAll: "Select All",
      serialNo: "Serial No",
      noOfBiddersBids: "No Of Bidders / Bids",
      remarks: "Remarks",
      replied: "Replied",
      sorrynomatchingrecordsfound: "Sorry, no matching records found",
      diamondandParcel: "Diamond and Parcel",
      jewellery: "Jewellery",
      negotiate: "Negotiate",
      calenderMonths: "Calender Months",
      timeleft: "Time left",
      home: "Home",
      preview: "Preview",
      auction: "Auction",
      watchlist: "watchlist",
      invoice: "Invoice",
      profile: "Profile",
      product: "product",
      other: "Other",
      totalAmounts: "Total Amount",
      surelogout: "Are you sure you want to logout?",
      CANCEL: "CANCEL",
      OK: "OK",
      prductDaysauction: "365 Days auction",
      productDaysAuctionInvoices: "365 Days Auction Invoices",
      givedetails: "Give details",
      getyourAuctionapproved: "Get your Auction approved",
      sellandEnjoy: "Sell and Enjoy",
    },
    zipangLanding: {
      tab: {
        upcomingAuc: 'UPCOMING AUCTIONS',
        about: 'ABOUT',
        sell: 'SELL',
        buy: 'BUY',
        contact: 'CONTACT',
        ourTeam: 'OUR TEAM',
      },
      ourTeam: {
        ourTeamheading: 'Our Team',
        ourteamSubHeading: 'Empowering Bidding Brilliance Unveiled At Zipang',
        whyChooseus: 'WHY CHOOSE US?',
        seemLessHeading: 'Seamless Bidding: Where Satisfaction Meets Every Auction',
        team: {
          member1: {
            name: 'SHOBHIT SUCHANTI',
            title: 'President',
            description: 'Shobhit Suchanti is a GIA diamond-qualified merchant. Suchanti has a long history of 10 years in the Japanese auction industry. Here, he mastered importing and exporting diamonds to Japan. Today, Suchanti is a domain expert at My Auctions.'
          },
          member2: {
            name: 'HARUKA ARAI',
            title: 'COO',
            description: "The auction will be working under the expert guidance of 新井春芽 ,Arai San , Ex president of NJKP Auction. Getting to learn from his 40 years of veteran experience in the Japan Auction Industry, we couldn't be more fortunate!"
          },
          member3: {
            name: 'SARTHAK JAIN',
            title: 'HK Office Correspondent',
            description: 'Sarthak Jain serves as the head sales manager at the Zipang Auctions Japan and Hong Kong office. With Jain’s dedication and hardwork, the team at Zipang Auctions continues to thrive and excel in the competitive world of natural diamond auctions. Jain ensures that the office in Hong Kong operates seamlessly.'
          },
          member4: {
            name: 'YUKO OGINO',
            title: 'Tokyo Office Correspondent',
            description: 'Yuko Ogino is recognized as the longest-serving Japanese correspondent at the Zipang Auctions office in Japan. With a wealth of experience, Ogino nurtures strong client relationships, handles inquires and meticulously photographs diamonds from all directions for auction purposes.'
          },
        },
        seller: {
          tile1: {
            heading: 'World-class buyers',
            description: 'India, China, America etc.  A large number of strong buyers have passed our screening and proven their suitability.  A network of approximately 9,000 companies.'
          },
          tile2: {
            heading: 'Even people who are not good at computers are welcome.',
            description: 'Leave it to us.  We will take care of your important items responsibly.  The customer is in control of the transparent auction.'
          },
          tile3: {
            heading: '360° image capture',
            description: 'Sending 360° shooting videos to the world.  Buyers from all over the world can view your items online.'
          },
          tile4: {
            heading: 'Supported by a specialized team with strong overseas expertise',
            description: 'All customer personal information is protected by encryption.  Those who register as buyers are required to submit documents such as ID and business history, and their identity is rigorously screened.'
          },
        },
        buyer: {
          tile1: {
            heading: 'High quality product lineup from Japan Pawn Market',
            description: 'Access to an assortment of QUALITY diamonds handpicked by our Diamond merchants suitable for your market.'
          },
          tile2: {
            heading: 'Professional Team for your support',
            description: 'Leave it to us.  We will take care of your important items responsibly.  The customer is in control of the transparent auction.'
          },
          tile3: {
            heading: 'Buy from comfort of your seat',
            description: 'The Diamonds come with every minute detail possible, 360degree video, images with loupe enabling you to buy diamonds in Japan without the loss of precious time,expenses while removing the language barriers.'
          },
          tile4: {
            heading: 'Taking the Japan Pawn Auctions to the internet age',
            description: 'Our 10 sec bidding auction, not only provides an exciting experience but also  levels up transparency and accountability. Take your paddle up and win the stone, online from your phone!'
          },
        }
      },
      heading: {
        fistHeading: 'Your Partners in',
        secHeading: 'Selling and Buying',
        subHading: "We are Japan's fastest growing online auction",
        upcomingAuctions: 'UPCOMING AUCTIONS',
        seller: 'SELLER',
        buyer: 'BUYER',
      },
      button: {
        reserveSlot: 'RESERVE YOUR SLOT',
      },
      sellerTrans: {
        firstBox: {
          heading: 'World Wide Top Class Buyers',
          description: 'We expose your diamond to thousands of our international clients, no need for foreign language skills nor direct negotiations. All our clients are approved members of the international trade that have gone through our screening and provided strong references to prove their eligibility.',
        },
        secondBox: {
          heading: 'Credibility',
          description: 'A team of experts that authenticate and answer to all your concerns regarding the item you are interested in, beyond the certificate and imagery !',
        },
        thirdBox: {
          heading: 'Transparency',
          description: 'You are ultimately in control of the auction. Check your stone from My Page and get every minute details of your stone.',
        },
        GiveDetails: {
          title: '1. GIVE DETAILS',
          heading: 'Submit the list of stones you want to submit in a csv of the list attached over email/whatsapp/line/wechat and our team of experts will get started!',
          subHeading: '',
        },
        sendGoods: {
          title: '2. SEND GOODS',
          heading: 'Send the goods you have attached in the sheet to our address and our team will keep you posted after checking them with the sheet!',
          subHeading: 'Japan: 4 Chome-10-7 Taito, Taito City, Tokyo 110-0016',
          thirdSubHeading: 'Hongkong: Unit 11 5F Block A Focal Industrial Centre 21 Man Lok St, Hung Hom, Hong Kong',
        },
        sellorEnjoy: {
          title: '3. SELL & ENJOY',
          heading: 'We use our expertise, technology and connections to get jewelry sellers the absolute most for their jewelry.',
          subHeading: '',
        }
      },
      buyerTrans: {
        firstBox: {
          heading: 'Lineup of goods',
          description: 'Exposure to abundance of hand picked items, from JAPAN PAWN MARKET, from the comfort of your own PC or mobile device. Our ultimate motive is to deliver only quality goods!',
        },
        secondBox: {
          heading: 'Tech Savvy',
          description: 'The ability to buy diamonds in Japan without the loss of precious time and expenses involved in traveling, avoiding the commission export companies charge for shipping and local consumption tax financing. The Diamonds come with every minute detail possible, 360degree video, images with loupe and all our expert guidance.',
        },
        thirdBox: {
          heading: 'Transparency and Credibility',
          description: 'A team of experts that authenticate and answer to all your concerns regarding the item you are interested in, beyond the certificate and imagery !',
        },
      },
      registerTitle: 'REGISTER. GET VERIFIED. BUY EVERYTHING!',
      footer: {
        japanAddress: "",
        honkongAddress: "",
        clientDetails: "",
        contactSection: {
          email: '',
          TermsandService: '',
          companyDetails: ''
        }
      }
    }
  },
  trans: {
    error: "Error",
    removeFromWatchList: "Removed from watchlist successfully",
    fieldSelect: "-- Select --",
    pleaseEnterFromWeight: "Please enter from weight",
    supportCaps: "SUPPORT",
    mazal: "MAZAL!!",
    endDateGreaterThanStartDate: "End date should be greater than start date",
    pleaseVerifyCaptchs: "Please verify captcha",
    productDescriptionError: "Please provide product description.",
    subdomainAlreadyExists: "Subdomain already exits",
    saveAsDraft: "SAVE AS DRAFT",
    businessDetails: "Business Details",
    businessRegistrationNumber:
      "Business Registration Number / Incorporation Certificate ­OR A License or Evidence, which entitled you to run this Particular Business",
    dateOfRegAndCertificate: "Date Of Registration / Certificate",
    incorporateCertificate: "Incorporation Certificate",
    noOfYearsInBusiness: "No. of Years in Business",
    natureOfBusiness: "Nature of Business",
    kycReferenceCompany:
      "Reference company (Preferably My Auctions approved user)",
    kycReferenceDetails: "Reference details",
    kycOtherGroupCompany:
      "Other Group Company / Sister Concerns / Associates etc (non mandatory field)",
    payNow: "Pay Now",
    noteAddedSuccessfully: "Note added successfully",
    prebidPriceSavedSuccessfully: "Pre-bid price saved successfully",
    prebidPriceRemovedSuccessfully: "Pre-bid price removed successfully",
    addToWatchlistSuccessfully: "Added into watchlist successfully",
    removedFromWatchlistSuccessfully: "Removed from watchlist successfully",
    errorMessage: {
      operationNotAllowed: "Operation not allowed",
      tokenExpired: "Token Exired",
      notAllowedToAccess:
        "You are not allowed to access myauctions , Please subscribe for complete access.",
      dontHaveInvitationToAccess: "You don't have any invitation to access.",
      accessRestrictedContactUs:
        "We have restricted your access to MyAuctions.jp. Please contact MyAuctions or mail us at contact@myauctions.jp",
      paymentSucess: "Payment Success",
      otpExpired: "Otp Expired",
      unknownErrorServerSideException: "Unknown error from server",
      userPaymentFail: "error occurred while payment",
    },
  },
};
